<template>
  <div>
    <div class="flex">
      <div class="w-full">
        <vs-divider position="left" background="success" class="mt-2" v-if="showIndex">
            {{ $t('questionnaire_corrector.criteria') }} : {{index+1}}
        </vs-divider>
      </div>
      <div class="flex justify-end max-w-50" v-if="showButtonDelete">
        <vs-button
            color="danger"
            type="flat"
            icon-pack="feather"
            icon="icon-trash"
            v-tooltip="{
              content: $t('action.delete'),
              delay: { show: 500, hide: 100 }
            }"
            @click="deleteCriteria()"></vs-button>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
      <div class="flex w-full w-5/6">
        <vs-input
          class="w-full required"
          v-validate="'required|max:191'"
          :label="$t('questionnaire_corrector.evaluation.criteria')"
          v-model="criteriaModel.title"/>
      </div>
      <div class="flex w-1/6">
        <vx-input-group>
          <vs-input
            lang="pt-BR"
            type="number"
            class="required"
            v-validate="'required|numeric'"
            :label="$t('questionnaire_corrector.evaluation.weight')"
            v-model="criteriaModel.weight"
            @change="scoreChange"
            v-on:keyup="validateKeyPresses"/>
            <template slot="append">
                 <div class="append-text btn-addon flex items-end">
                    <span class="input-group-text includeIcon includeIconOnly vs-component">%</span>
                  </div>
              </template>
        </vx-input-group>
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <complex-editor
            v-bind:editor_data.sync="criteriaModel.description"
            @complex-editor-is-open="editorIsOpen"/>
      </div>
    </div>
    <div class="w-full flex gap-2 justify-end" v-if="showButtonAdd">
        <vs-button
          v-permission="'questionnaire_correctors.edit'"
          :disabled="!validateForm"
          type="border"
          icon="add"
          @click="addCriteria()">{{ $t('adicionar') }}
        </vs-button>
      </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ComplexEditor from '../questionnaires/elements/ComplexEditor.vue'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ComplexEditor,
  },
  props: {
    model: {
      default: null,
      type: Object
    },
    questionId:{
      default: null,
      type: String
    },
    index: {
      default: 0,
      type: Number
    },
    showIndex: {
      default: true,
      type: Boolean
    },
    showButtonAdd: {
      default: false,
      type: Boolean
    },
    showButtonDelete: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    criteriaModel:{
      id: null,
      question_id: null,
      title : '',
      weight: '',
      description: null
    }
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(_.get(this.criteriaModel, 'title'))
              && !this.isEmpty(_.get(this.criteriaModel, 'weight'))
    }
  },
  watch: {

  },
  beforeMount() {

  },
  mounted() {
    if(this.model){
      this.criteriaModel = this.model
    }
  },

  methods: {
    editorIsOpen: function (val){
      console.log('ckEdditorIsOpen in criteria: '+val)
      if(this.questionId){
        this.$emit('complexEditorOpened', this.questionId)
      }
    },
    addCriteria(){
      this.criteriaModel.id = this.$uuidKey()
      this.criteriaModel.question_id = this.questionId
      this.$emit('addCriteria', this.questionId, this.criteriaModel)
    },
    clearFormCriteria(){
      this.criteriaModel.id = ''
      this.criteriaModel.title = ''
      this.criteriaModel.weight = ''
      this.criteriaModel.description = ''
    },
    deleteCriteria(){
      this.$emit('deleteCriteria', this.questionId, this.index)
    },
    scoreChange() {
      let score = this.criteriaModel.weight
      try{
          Number.parseInt(score)
      }catch(err){
        this.criteriaModel.weight = 0
      }
      //
      if (score && Number.parseInt(score) < 0) {
        this.criteriaModel.weight = 0
      }
      if (score && Number.parseInt(score) > 100) {
        this.criteriaModel.weight = 100
      }

      console.log('Weight changing...:'+this.criteriaModel.weight)
      this.$emit('validateWeight', this.questionId, this.criteriaModel)

    },
    validateKeyPresses(){
      let score = this.criteriaModel.weight
      if(!this._.isNumber(score)){
        //this.criteriaModel.weight = ''
      }
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss" scopped>
  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #eee;
    border-radius: 5px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
</style>

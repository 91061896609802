import Rest from '../Rest'

export default class QuestionnaireCorrectorService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/questionnaire_correctors'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  // Teachers
  addTeachers(questionnaireCorrectorId, data) {
    return this.post(`${questionnaireCorrectorId}/teachers`, data).then(
      (data) => {
        return data
      }
    )
  }
  removeTeacher(questionnaireCorrectorId: Number, participantsIds) {
    return this.put(`${questionnaireCorrectorId}/teachers/remove`,{
      participants: participantsIds
    })
  }

  findQuestions(id) { //questionnaireCorrectorId
    return this.get(`${id}/questions`);
  }

  findCriteria(id) { //questionnaireCorrectorId
    return this.get(`${id}/criteria`);
  }

  findQuestionAnswers(id, answerId) { //(questionnaireCorrectorId, answerId)
    return this.get(`${id}/answers/${answerId}`);
  }

  saveCorrection(id: Number, answerId: number, questions) {
    return this.put(`${id}/answers/${answerId}`,{
      corrections: questions
    })
  }

  finalizeCorrection(id: Number, answerId: Number) {
    return this.put(`${id}/answers/${answerId}/finalize`,{})
  }

  openCorrection(id: Number, answerId: Number) {
    return this.put(`${id}/answers/${answerId}/open`,{})
  }
}

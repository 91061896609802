import { render, staticRenderFns } from "./CorrectionCriteria.vue?vue&type=template&id=741d37a4&"
import script from "./CorrectionCriteria.vue?vue&type=script&lang=js&"
export * from "./CorrectionCriteria.vue?vue&type=script&lang=js&"
import style0 from "./CorrectionCriteria.vue?vue&type=style&index=0&lang=scss&scopped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/user/workspace/educatena/educatena-app-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('741d37a4')) {
      api.createRecord('741d37a4', component.options)
    } else {
      api.reload('741d37a4', component.options)
    }
    module.hot.accept("./CorrectionCriteria.vue?vue&type=template&id=741d37a4&", function () {
      api.rerender('741d37a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/questionnaire_corrector/CorrectionCriteria.vue"
export default component.exports